<template>
    <PageLayout
        class="nomenclature-detail"
    >
        <v-container fluid>
            <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-form @submit.prevent="saveItem">
                    <v-row>
                        <v-col>
                            <h1>{{ $t('incoming.self') }} #{{ id }}</h1>

                            <v-icon small @click="onDeleteBtnClick">mdi-trash-can-outline</v-icon>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card>
                                <v-card-title>
                                    {{ $t('general_info') }}
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`organization_id`" rules="required|min:1" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    v-model="organization_id"
                                                    :items="organizations.map(item => ({ text: item.name, value: item.id }))"
                                                    :label="$t('columns.incoming.organization')"
                                                    :error-messages="errors"
                                                    id="organization_id"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12"  md="6">
                                            <ValidationProvider :ref="`warehouse_id`" rules="required" v-slot="{ errors, valid }">
                                                <SelectInput
                                                    v-model="warehouse_id"
                                                    :items="warehouses.map(item => ({ text: item.name, value: item.id }))"
                                                    :label="$t('columns.incoming.warehouse')"
                                                    :error-messages="errors"
                                                    id="warehouse_id"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`uved_id`" rules="required|min:1" v-slot="{ errors, valid }">
                                                <UvedSelection
                                                    id="uved_id"
                                                    :label="$t('columns.incoming.uved')"
                                                    v-model="uved"
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`delivery_date`" rules="required" v-slot="{ errors, valid }">
                                                <label class="input_label">{{ $t('columns.incoming.delivery_date') }}</label>
                                                <DatetimePicker
                                                    format="24hr"
                                                    v-model="delivery_date"
                                                    :label="$t('columns.incoming.delivery_date')"
                                                    :text-field-props="{ 'error-messages': errors, error: !valid }"
                                                    validatorName="delivery_date"
                                                    validator-rules="required"
                                                    id="delivery_date"
                                                ></DatetimePicker>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider :ref="`delivery_address`" rules="" v-slot="{ errors, valid }">
                                                <TextInput
                                                    v-model="delivery_address"
                                                    :label="$t('columns.incoming.delivery_address')"
                                                    :error-messages="errors"
                                                    id="delivery_address"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="6">
                                            <ValidationProvider :ref="`comment`" rules="" v-slot="{ errors, valid }">
                                                <TextInput
                                                    v-model="comment"
                                                    :label="$t('columns.incoming.comment')"
                                                    :error-messages="errors"
                                                    id="comment"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="buttons-block">
                            <Btn v-if="!passed" type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="secondary">
                                <template v-if="saved">
                                    {{ $t('save') }}
                                </template>
                                <template v-else>
                                    {{ $t('add') }}
                                </template>
                            </Btn>
                            <template v-if="saved">
                                <Btn v-if="passed" @click="onRevokeClick" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="error">
                                    {{ $t('revoke') }}
                                </Btn>
                                <Btn v-else @click="onPassClick" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('pass') }}
                                    <v-icon right>
                                        mdi-check-circle-outline
                                    </v-icon>
                                </Btn>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <Btn
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ $t('create_based_on') }}
                                            <v-icon
                                                right
                                                dark
                                            >
                                                mdi-menu-down
                                            </v-icon>
                                        </Btn>
                                    </template>
                                    <v-list>
                                        <v-list-item :to="{name: 'incoming_returning.edit', params: {uuid: 'create'}, query: {incoming_returning_uuid: uuid}}">{{ $t('incoming_returning.self') }}</v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-col>
                        <v-col cols="12" v-if="loading">
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" indeterminate :active="loading" class="mx-2"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>

            <v-row class="mt-4">
                <v-col>
                    <ItemEditDialog
                        :value="addItemDialogShown"
                        @add="onItemAdd"
                        @input="addItemDialogShown = $event"
                    ></ItemEditDialog>

                    <ResponsiveTabs :value="tab" @input="tab = $event">
                        <ResponsiveTab :title="$t('incoming_item.plural')">
                            <template v-slot="{}">
                                <ItemsComponent
                                    :warehouse_id="warehouse_id"
                                    :addItemDialogShown="addItemDialogShown"
                                    :items="items"
                                    @change="onItemUpdate"
                                    @add="onItemAdd"
                                    @delete="onItemDelete"
                                    @add-item-click="onAddBtnClick"
                                    @add-item-dialog-close="onAddItemDialogClose"
                                />
                            </template>
                            <template v-slot:btn-after>
                                <div class="tab__btns">
                                    <v-icon class="ml-2" color="secondary" dense @click="$event.stopPropagation(); addItemDialogShown = true">mdi-plus-circle-outline</v-icon>
                                </div>
                            </template>
                        </ResponsiveTab>
                        <ResponsiveTab :title="$t('document.path')">
                            <DocPath :uuid="uuid" :path="path"></DocPath>
                        </ResponsiveTab>
                    </ResponsiveTabs>
                </v-col>
            </v-row>
        </v-container>
    </PageLayout>
</template>


<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import UvedSelection from "@/components/Form/UvedSelection.vue";
import DatetimePicker from "@/components/DatetimePicker.vue";
import WithOrganizations from "@/plugins/mixins/WithOrganizations";
import WithWarehouses from "@/plugins/mixins/WithWarehouses";
import WithIncomingItem from "@/plugins/mixins/WithIncomingItem";
import WithOrderToBuy from "@/plugins/mixins/WithOrderToBuy";
import DocumentMixin from "@/plugins/mixins/DocumentMixin";
import ResponsiveTabs from "@/components/Leentech/ResponsiveTabs.vue";
import DocPath from "@/components/DocPath.vue";
import ResponsiveTab from "@/components/Leentech/ResponsiveTab.vue";

import ItemsComponent from "@/components/Warehouse/Buy/IncomingItems.vue";
import ItemEditDialog from "@/components/Warehouse/Buy/IncomingItemEditDialog.vue";

export default {
    name: 'IncomingEdit',

    mixins: [
        WithOrganizations,
        WithWarehouses,
        WithIncomingItem,
        WithOrderToBuy,
        DocumentMixin
    ],

    components: {
        ItemEditDialog,
        DocPath,
        ResponsiveTab,
        ResponsiveTabs,
        ItemsComponent,
        DatetimePicker,
        UvedSelection,
        SelectInput,
        Btn,
        ValidationProvider,
        ValidationObserver,
        TextInput
    },

    inject: ['forceRerender'],

    props: {
        uuid: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            documentUrl: 'buy/incoming',

            dialogForm: true,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            addItemDialogShown: false,
            based_on_id: null,

            /*  document fields */
            order: null, // все данные в том виде как пришли с серва
            organization_id: null,
            uved: null,
            warehouse_id: null,
            delivery_date: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            delivery_address: null,
            comment: null,
            items: [],
            path: null
        }
    },

    computed: {
        ...mapGetters(['listLanguages', 'lang']),

        languages() {
            return this.listLanguages
        },
    },

    watch: {
        organizations: {
            handler: function (value) {
                if (this.organization_id === null && value.length > 0) {
                    this.organization_id = value[0].id;
                }
            },

            immediate: true
        },

        uuid: {
            handler: function (value) {
                if (value !== 'create') {
                    this.fetchItem()
                } else {
                    if(this.$route.query.order_to_buy_uuid) {
                        this.fetchOrderToBuy(this.$route.query.order_to_buy_uuid).then((data) => {
                            this.organization_id = data.organization_id;
                            this.uved = data.uved
                            this.warehouse_id = data.warehouse_id
                            this.delivery_date = data.delivery_date ?? this.$moment().format('YYYY-MM-DD HH:mm:ss')
                            this.delivery_address = data.delivery_address
                            this.comment = data.comment
                            this.based_on_id = data.id
                            this.items = data.items.map((item) => {
                                item.id = null;
                                return item;
                            })

                            this.order = data
                        })
                    }
                }
            },

            immediate: true
        },
    },

    async mounted() {
        this.language = this.languages[this.tab]
        this.initOrganizations();
        this.initWarehouses();
    },

    methods: {
        onAddItemDialogClose(value) {
            this.addItemDialogShown = false;
        },

        closeDialogAdd() {
            this.$router.push({
                name: 'incoming',
            })
        },

        async fetchItem() {
            let _this = this;
            let params = {}

            this.progress = 0
            this.loading = true

            if (this.language) {
                params.language = this.language
            }

            await this.$http
                .get(`admin/buy/incoming/${this.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res && res.body && res.body.data) {
                        this.handleResponse(res.body.data)
                        // this.fetchItems(this.uuid)
                        //     .then((body) => {
                        //         this.items = body.data
                        //     })
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('incoming.fetching-error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async saveItem() {
            let _this = this;

            this.progress = 0
            this.loading = true

            let data = {
                organization_id: this.organization_id,
                uved_id: this.uved.id,
                warehouse_id: this.warehouse_id,
                delivery_date: this.delivery_date,
                delivery_address: this.delivery_address,
                comment: this.comment,
                items: this.items.map(item => {
                    return {
                        id: item.id ? item.id : null,
                        nomenclature_id: item.nomenclature.id,
                        price: item.price + '',
                        vat_id: item.vat_id,
                        quantity: item.quantity,
                        discount: item.discount,
                        warehouse_cell_id: item.warehouse_cell_id,
                    }
                })
            }

            if (this.uuid === 'create') {
                if(this.based_on_id) {
                    data.based_on_id = this.based_on_id;
                }

                await this.$http
                    .post('admin/buy/incoming', data)
                    .then(res => {
                        this.$toastr.success(this.$t('incoming.added'))
                        this.$router.push({
                            name: 'incoming.edit',
                            params: {
                                uuid: res.body.data.uuid
                            }
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('incoming.add_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                await this.$http
                    .put(`admin/buy/incoming/${this.uuid}`, data, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('incoming.update_success'));
                        this.handleResponse(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('incoming.update_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        handleResponse(data) {
            this.id = data.id

            this.organization_id = data.organization_id
            this.uved = data.uved
            this.warehouse_id = data.warehouse_id
            this.delivery_date = data.delivery_date ?? this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.delivery_address = data.delivery_address
            this.comment = data.comment
            this.passedAt = data.passed_at
            this.items = data.items
            this.path = data.path

            this.order = data
        },

        onDeleteBtnClick() {
            if (confirm(this.$t('incoming.delete_confirm'))) {
                this.loading = true

                this.deleteDocument()
                    .then(() => {
                        this.$toastr.success(this.$t('incoming.deleted'))
                        this.$router.push({
                            name: 'warehouse.selling.incoming',
                        })
                    })
                    .catch(() => {
                        this.$toastr.error(this.$t('incoming.delete_error'))
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },

        onItemAdd(itemData) {
            this.items.push(itemData);
            this.items = [...this.items];
            this.$set(this, 'items', this.items);

            this.addItemDialogShown = false;

            // this.postItem(this.uuid, itemData)
            //     .then((data) => {
            //
            //     })
            //     .catch(() => {
            //
            //     });
        },

        onItemUpdate(itemData, value, field) {
            let index = this.items.findIndex((item, index) => {
                return (item.uuid === itemData.uuid);
            });

            this.items.splice(index, 1, itemData);

            this.items = [...this.items];
            this.$set(this, 'items', this.items);

            // this.updateItem(this.uuid, itemData.uuid, itemData).th en((data) => {
            //     this.$toastr.success(this.$t('incoming_item.updated'))
            //     let item = this.items.findIndex((item) => item.uuid === data.uuid);
            //     this.$set(this.items, item, data)
            // }).catch((error) => {
            //     console.error(error)
            //     this.$toastr.error(this.$t('incoming_item.update_error'))
            // })
        },

        onItemDelete(itemData) {
            this.items.find((item, index) => {
                if (item.uuid === itemData.uuid) {
                    this.items.splice(index, 1);
                    return true;
                }
            });
            // this.deleteItem(this.uuid, itemData.uuid).then(() => {
            //     this.$toastr.success(this.$t('incoming_item.deleted'))
            //
            //     this.fetchItems(this.buyerOrder.uuid).then((body) => {
            //         this.items = body.data
            //     })
            // }).catch((err) => {
            //     console.error(err)
            //     this.$toastr.error(this.$t('order_to_buy_item.delete_error'))
            // })
        },

        onAddBtnClick() {
            this.addItemDialogShown = true;
        },
    }
}
</script>
